import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../assets/pen_logo.png";

interface HeaderProps {
  activeSection: string;
}

const Header: React.FC<HeaderProps> = ({ activeSection }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [debouncedActive, setDebouncedActive] = useState(activeSection);
  const [clickedSection, setClickedSection] = useState<string | null>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    if (!clickedSection) {
      setDebouncedActive(activeSection);
    }
  }, [activeSection, clickedSection]);

  useEffect(() => {
    if (clickedSection) {
      const timer = setTimeout(() => {
        setClickedSection(null);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [clickedSection]);

  useEffect(() => {
    // Prevent scrolling when mobile menu is open
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isMenuOpen]);

  const handleNavClick = async (sectionId: string, e: React.MouseEvent) => {
    e.preventDefault();
    setClickedSection(sectionId);
    setDebouncedActive(sectionId);
    setIsMenuOpen(false); // Close mobile menu when clicking a link

    if (location.pathname !== "/") {
      await navigate("/");
      setTimeout(() => {
        const element = document.getElementById(sectionId);
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }, 50);
    } else {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  };

  const getActiveClass = (sectionId: string) => {
    if (clickedSection === sectionId) {
      return "active";
    }
    if (
      sectionId === "services_container" &&
      location.pathname === "/" &&
      (debouncedActive === "services_container" ||
        document.getElementById("services_container")?.getBoundingClientRect()
          .top === 0)
    ) {
      return "active";
    }
    return debouncedActive === sectionId ? "active" : "";
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const navLinks = (
    <>
      <li className="navbar_list_comp">
        <Link
          to="/#aboutus_container"
          className={`navbar_link_button ${getActiveClass("aboutus_container")}`}
          onClick={(e) => handleNavClick("aboutus_container", e)}
        >
          ABOUT PEN
        </Link>
      </li>
      <li className="navbar_list_comp">
        <Link
          to="/#services_container"
          className={`navbar_link_button ${getActiveClass("services_container")}`}
          onClick={(e) => handleNavClick("services_container", e)}
        >
          SERVICES
        </Link>
      </li>
      <li className="navbar_list_comp">
        <Link
          to="/#joinus_container"
          className={`navbar_link_button ${getActiveClass("joinus_container")}`}
          onClick={(e) => handleNavClick("joinus_container", e)}
        >
          JOIN US
        </Link>
      </li>
      <li className="navbar_list_comp">
        <Link
          className={`navbar_link_button ${
            location.pathname === "/scholar-awards" ? "active" : ""
          }`}
          to="/scholar-awards"
        >
          SCHOLAR AWARDS
        </Link>
      </li>
      <li className="navbar_list_comp">
        <Link
          to="/#contactus_container"
          className={`navbar_link_button ${getActiveClass("contactus_container")}`}
          onClick={(e) => handleNavClick("contactus_container", e)}
        >
          CONTACT
        </Link>
      </li>
    </>
  );

  return (
    <nav className="nav_option_div">
      <div className="pen_logo_component d-flex justify-content-start align-items-center ps-3">
        <Link 
        to="/#carousel"
        className={`navbar_link_button ${getActiveClass("carousel")}`}
        onClick={(e) => handleNavClick("carousel", e)}>
          <img src={Logo} alt="Pen Logo" className="pen_logo" />
        </Link>
      </div>
      <ul className="nav_bar_main_list_component d-flex justify-evenly align-items-center">
        {navLinks}
      </ul>
      
      <div className={`burger_menu ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
        <div></div>
        <div></div>
        <div></div>
      </div>

      <div className={`mobile_menu ${isMenuOpen ? 'open' : ''}`}>
        <ul className="mobile_menu_list">
          {navLinks}
        </ul>
      </div>
    </nav>
  );
};

export default Header;