import { motion } from "framer-motion";
import React, { useState } from "react";
import "../styles/Contact.scss";

export const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleSubmit = (event: any) => {
    event.preventDefault();


    setErrors({
      name: "",
      email: "",
      message: "",
    });

    const newErrors: any = {};
    if (!formData.name) newErrors.name = "Name is required";
    if (!formData.email) newErrors.email = "Email is required";
    if (!formData.message) newErrors.message = "Message is required";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return; 
    }

    console.log(formData); 
  };


  const handleChange = (event: any) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  return (
    <section className="contact">
      <div className="contact-container">
        <div className="contact-left">
          <div className="contact-form">
            <h1 className="contact-header">TALK TO US</h1>
            <div className="contact-description">
              Join us in unleashing the transformative power of data for social
              and political progress. Talk to us today.
            </div>
            <form onSubmit={handleSubmit}>
              <div className="form-details">
                <div className="name ">
                  <label className="contact-label" htmlFor="name">
                    Name
                  </label>
                  <input
                    type="text"
                    className="contact-textfield"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Enter your Name"
                  />
                    {errors.name && <p className="error-message">{errors.name}</p>}
                </div>
                <div className="email-id">
                  <label className="contact-label" htmlFor="email">
                    Email
                  </label>
                  <input
                    type="text"
                    className="contact-textfield"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Enter your Email ID"
                  />
                    {errors.email && <p className="error-message">{errors.email}</p>}
                </div>
                <div className="messages">
                  <label className="contact-label" htmlFor="message">
                    Message
                  </label>
                  <input
                    type="text"
                    className="contact-textfield"
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    placeholder="Enter your Message"
                  />
                    {errors.message && <p className="error-message">{errors.message}</p>}
                </div>
                <div className="submit">
                  <motion.button
                    whileHover={{
                      scale: 1.2,
                      transition: { duration: 1 },
                    }}
                    className="contact-submit"
                    whileTap={{ scale: 0.9 }}
                  >
                    Submit
                  </motion.button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="contact-right">
          <div className="map-container">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.943765506895!2d80.25819193342916!3d13.039251476712126!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52663382da23af%3A0x48ae534358c580b2!2sPEN!5e0!3m2!1sen!2sin!4v1684741425200!5m2!1sen!2sin"
              loading="lazy"
              className="pen-india-map"
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
};
