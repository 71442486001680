import React, { useEffect, useState, useRef } from "react";
import "../styles/Carousel.scss";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Marquee from "react-fast-marquee";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import IMG1 from "../assets/Laptop/1.png";
import IMG2 from "../assets/Laptop/2.png";
import IMG3 from "../assets/Laptop/3.png";
import IMG4 from "../assets/Laptop/4.png";
import MOB1 from "../assets/Mobile/Mob1.png";
import MOB2 from "../assets/Mobile/Mob2.png";
import MOB3 from "../assets/Mobile/Mob3.png";
import MOB4 from "../assets/Mobile/Mob4.png";
import TAB1 from "../assets/Tab/Tab1.png";
import TAB2 from "../assets/Tab/Tab2.png";
import TAB3 from "../assets/Tab/Tab3.png";
import TAB4 from "../assets/Tab/Tab4.png";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Autoplay, Pagination, Navigation } from "swiper";
import { Link } from "react-router-dom";

function topCarouselContainer() {
  const carousel_images = [IMG1, IMG2, IMG3, IMG4];
  const carousel_mobile_images = [MOB1, MOB2, MOB3, MOB4];
  const carousel_tab_images = [TAB1, TAB2, TAB3, TAB4];

  const carouselItems = carousel_images.map((image) => {
    return (
      <SwiperSlide className="carousel_slide_div">
        <img src={image} className="carousel_image" alt="Carousel Images" />
      </SwiperSlide>
    );
  });
  const carouselMobileItems = carousel_mobile_images.map((image) => {
    return (
      <SwiperSlide className="carousel_slide_div">
        <img src={image} className="carousel_image" alt="Carousel Images" />
      </SwiperSlide>
    );
  });
  const carouselTabItems = carousel_tab_images.map((image) => {
    return (
      <SwiperSlide className="carousel_slide_div">
        <img src={image} className="carousel_image" alt="Carousel Images" />
      </SwiperSlide>
    );
  });

  return (
    <>
      {/* <div className="main">
        <Marquee className="marquee" direction="left">
          <div className="Apply">
            Inviting Applications for PEN Scholar Awards to recognize and reward
            excellence in Ph.D. Research –
            <Link to="/scholar-awards" className="apply-link">
              {" "}
              Click Here to Apply
            </Link>
            <span className="gap">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>{" "} */}
            {/* Add space */}
          {/* </div>
        </Marquee>
      </div> */}

      <div className="landing_page_carousel_comp_div  d-flex justify-content-center align-items-center">
        <div className="carousel_component">
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            // navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="image_carousel_main_div desktop"
          >
            {carouselItems}
          </Swiper>
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            // navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="image_carousel_main_div mobile"
          >
            {carouselMobileItems}
          </Swiper>
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            // navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="image_carousel_main_div tab"
          >
            {carouselTabItems}
          </Swiper>
        </div>

        {/* <div className="carousel_content_div">
        <div className="carousel_title_div">TITLE</div>
        <div className="carousel_subtitle_div">CAROUSAL CONTENT</div>

        <div className="carousel_txt_div">Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur,</div>

        <div className="carousel_knowmore_div d-flex "><div className="knowmore_button_component_div"><button className="knowmore_button d-flex justify-content-start align-items-center ps-4 ">KNOW MORE</button><div className="knowmore_arrow shadow"><ArrowForwardIcon/></div></div></div>        
      </div> */}

        <div className="carousel_social_links">
          <div className="social_icons">
            <a href="https://www.facebook.com/PEN.Offl/" target={"_blank"}>
              <svg
                className="social-media-icon"
                xmlns="http://www.w3.org/2000/svg"
                width="67"
                height="68"
                viewBox="0 0 67 68"
              >
                <defs>
                  <filter
                    id="Ellipse_15"
                    x="0"
                    y="0"
                    width="67"
                    height="68"
                    filterUnits="userSpaceOnUse"
                  >
                    <feOffset dy="3" />
                    <feGaussianBlur stdDeviation="3" result="blur" />
                    <feFlood flood-opacity="0.161" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                </defs>
                <g
                  id="_001-facebook"
                  data-name="001-facebook"
                  transform="translate(8.679 6.386)"
                >
                  <g
                    transform="matrix(1, 0, 0, 1, -8.68, -6.39)"
                    filter="url(#Ellipse_15)"
                  >
                    <ellipse
                      id="Ellipse_15-2"
                      data-name="Ellipse 15"
                      cx="24.5"
                      cy="25"
                      rx="24.5"
                      ry="25"
                      transform="translate(9 6)"
                      fill="#3b5998"
                    />
                  </g>
                  <path
                    id="Path_16"
                    data-name="Path 16"
                    d="M51.971,37.732H47.595V53.763h-6.63V37.732H37.812V32.1h3.153V28.453c0-2.607,1.238-6.69,6.689-6.69l4.911.021v5.469H49A1.35,1.35,0,0,0,47.6,28.788V32.1H52.55Z"
                    transform="translate(-21.174 -12.124)"
                    fill="#fff"
                  />
                </g>
              </svg>
            </a>
          </div>

          <div className="social_icons">
            <a href="https://www.instagram.com/PEN.Offl/" target={"_blank"}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="67"
                height="68"
                viewBox="0 0 67 68"
                className="social-media-icon"
              >
                <defs>
                  <filter
                    id="XMLID_30_"
                    x="0"
                    y="0"
                    width="67"
                    height="68"
                    filterUnits="userSpaceOnUse"
                  >
                    <feOffset dy="3" />
                    <feGaussianBlur stdDeviation="3" result="blur" />
                    <feFlood flood-opacity="0.161" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                </defs>
                <g
                  id="Group_15053"
                  data-name="Group 15053"
                  transform="translate(-6001 -6901.458)"
                >
                  <g
                    id="_004-google-plus"
                    data-name="004-google-plus"
                    transform="translate(6009.969 6907.844)"
                  >
                    <g
                      id="Group_40"
                      data-name="Group 40"
                      transform="translate(0 0)"
                    >
                      <g
                        transform="matrix(1, 0, 0, 1, -8.97, -6.39)"
                        filter="url(#XMLID_30_)"
                      >
                        <ellipse
                          id="XMLID_30_2"
                          data-name="XMLID_30_"
                          cx="24.5"
                          cy="25"
                          rx="24.5"
                          ry="25"
                          transform="translate(9 6)"
                          fill="#d5296b"
                        />
                      </g>
                    </g>
                  </g>
                  <path
                    id="Icon_awesome-instagram"
                    data-name="Icon awesome-instagram"
                    d="M13.378,8.756a6.86,6.86,0,1,0,6.86,6.86A6.849,6.849,0,0,0,13.378,8.756Zm0,11.319a4.46,4.46,0,1,1,4.46-4.46,4.468,4.468,0,0,1-4.46,4.46Zm8.74-11.6a1.6,1.6,0,1,1-1.6-1.6A1.6,1.6,0,0,1,22.118,8.475ZM26.662,10.1A7.918,7.918,0,0,0,24.5,4.493a7.97,7.97,0,0,0-5.606-2.161c-2.209-.125-8.83-.125-11.039,0A7.959,7.959,0,0,0,2.25,4.487,7.944,7.944,0,0,0,.089,10.093c-.125,2.209-.125,8.83,0,11.039A7.918,7.918,0,0,0,2.25,26.737,7.98,7.98,0,0,0,7.856,28.9c2.209.125,8.83.125,11.039,0A7.918,7.918,0,0,0,24.5,26.737a7.97,7.97,0,0,0,2.161-5.606c.125-2.209.125-8.824,0-11.033ZM23.808,23.5a4.515,4.515,0,0,1-2.543,2.543c-1.761.7-5.94.537-7.886.537s-6.131.155-7.886-.537A4.515,4.515,0,0,1,2.948,23.5c-.7-1.761-.537-5.94-.537-7.886s-.155-6.131.537-7.886A4.515,4.515,0,0,1,5.492,5.185c1.761-.7,5.94-.537,7.886-.537s6.131-.155,7.886.537a4.515,4.515,0,0,1,2.543,2.543c.7,1.761.537,5.94.537,7.886S24.506,21.746,23.808,23.5Z"
                    transform="translate(6021.23 6916.864)"
                    fill="#fff"
                  />
                </g>
              </svg>
            </a>
          </div>

          <div className="social_icons">
            <a
              href="https://twitter.com/PEN_Offl"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                className="social-media-icon"
                xmlns="http://www.w3.org/2000/svg"
                width="67"
                height="68"
                viewBox="0 0 67 68"
              >
                <defs>
                  <filter
                    id="Path_5317"
                    x="0"
                    y="0"
                    width="67"
                    height="68"
                    filterUnits="userSpaceOnUse"
                  >
                    <feOffset dy="3" />
                    <feGaussianBlur stdDeviation="3" result="blur" />
                    <feFlood flood-opacity="0.161" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                </defs>
                <g
                  id="_002-twitter"
                  data-name="002-twitter"
                  transform="translate(9.028 6.386)"
                >
                  <g
                    transform="matrix(1, 0, 0, 1, -9.03, -6.39)"
                    filter="url(#Path_5317)"
                  >
                    <path
                      id="Path_5317-2"
                      data-name="Path 5317"
                      d="M24.5,0A24.754,24.754,0,0,1,49,25,24.754,24.754,0,0,1,24.5,50,24.754,24.754,0,0,1,0,25,24.754,24.754,0,0,1,24.5,0Z"
                      transform="translate(9 6)"
                      fill="#55acee"
                    />
                  </g>
                  <g
                    id="Group_39"
                    data-name="Group 39"
                    transform="translate(11.059 14.975)"
                  >
                    <path
                      id="Path_17"
                      data-name="Path 17"
                      d="M53.739,36.783a11.722,11.722,0,0,1-3.375.924,5.891,5.891,0,0,0,2.584-3.25,11.761,11.761,0,0,1-3.731,1.425,5.88,5.88,0,0,0-10.011,5.359A16.677,16.677,0,0,1,27.1,35.1a5.881,5.881,0,0,0,1.818,7.844,5.834,5.834,0,0,1-2.661-.736c0,.025,0,.05,0,.074a5.878,5.878,0,0,0,4.713,5.76,5.9,5.9,0,0,1-2.653.1,5.881,5.881,0,0,0,5.488,4.08,11.86,11.86,0,0,1-8.7,2.433,16.618,16.618,0,0,0,9.005,2.64c10.806,0,16.716-8.952,16.716-16.716,0-.255-.006-.508-.017-.76a11.914,11.914,0,0,0,2.932-3.041Z"
                      transform="translate(-25.103 -34.028)"
                      fill="#f1f2f2"
                    />
                  </g>
                </g>
              </svg>
            </a>
          </div>
          <div className="social_icons">
            <a
              href="https://www.linkedin.com/company/Populus-Empowerment-Network"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                className="social-media-icon"
                xmlns="http://www.w3.org/2000/svg"
                width="74.757"
                height="74.757"
                viewBox="0 0 74.757 74.757"
              >
                <defs>
                  <filter
                    id="Path_5318"
                    x="0"
                    y="0"
                    width="74.757"
                    height="74.757"
                    filterUnits="userSpaceOnUse"
                  >
                    <feOffset dy="3" />
                    <feGaussianBlur stdDeviation="3" result="blur" />
                    <feFlood flood-opacity="0.161" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                </defs>
                <g
                  id="Group_15052"
                  data-name="Group 15052"
                  transform="translate(-207.829 -50.756)"
                >
                  <g
                    transform="matrix(1, 0, 0, 1, 207.83, 50.76)"
                    filter="url(#Path_5318)"
                  >
                    <path
                      id="Path_5318-2"
                      data-name="Path 5318"
                      d="M25.012,0A25.012,25.012,0,1,1,0,25.012,25.012,25.012,0,0,1,25.012,0Z"
                      transform="matrix(0.99, -0.15, 0.15, 0.99, 9, 13.26)"
                      fill="#0054a2"
                    />
                  </g>
                  <g
                    id="Group_15014"
                    data-name="Group 15014"
                    transform="translate(232.933 72.888)"
                  >
                    <path
                      id="Path_5315"
                      data-name="Path 5315"
                      d="M249.667,97.377h5.094v16.363h-5.094Zm2.548-8.131a2.949,2.949,0,1,1-2.952,2.946,2.949,2.949,0,0,1,2.952-2.946"
                      transform="translate(-249.262 -89.246)"
                      fill="#fff"
                    />
                    <path
                      id="Path_5316"
                      data-name="Path 5316"
                      d="M266.763,105.21h4.878v2.238h.067a5.346,5.346,0,0,1,4.816-2.644c5.149,0,6.1,3.387,6.1,7.793v8.974h-5.082v-7.955c0-1.9-.037-4.339-2.643-4.339-2.647,0-3.052,2.067-3.052,4.2v8.094h-5.083Z"
                      transform="translate(-258.073 -97.08)"
                      fill="#fff"
                    />
                  </g>
                </g>
              </svg>
            </a>
          </div>
        </div>

        {/* Carousel */}
      </div>
    </>
  );
}

export default topCarouselContainer;
